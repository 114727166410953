import Button from 'components/Button'
import { useEffect, useRef, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import ReactDOM from 'react-dom'
import isClient from 'utils/Browser/isClient'
import makeTrackings from 'utils/make/trackings'
import CloseIcon from '../../../../../../static/images/icons/close.svg'
import { _formatters } from '@redsales/ds/utils'

import './LoanValueModal.scss'

const { toCurrency } = _formatters

const LoanValueModal = ({
  isOpen,
  setIsOpen,
  handleSelectedLoan,
  otherButtonPosition,
}) => {
  const modalRef = useRef()
  const [loanValue, setLoanValue] = useState('')

  useEffect(() => {
    const body = document.querySelector('body')

    if (isOpen) {
      body.style.overflow = 'hidden'
    } else {
      body.style.removeProperty('overflow')
    }
  }, [isOpen])

  const handleCloseModal = () => {
    const menu = modalRef.current
    menu.style.transition = '0.3s'
    menu.style.transform = 'translateY(100%)'

    setTimeout(() => {
      setIsOpen(false)
    }, 400)
  }

  const InvestmentModalLayout = (
    <div className="loan-value-modal">
      <div className="loan-value-modal__content" ref={modalRef}>
        <button
          className="loan-value-modal__close-button"
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </button>

        <div className="loan-value-modal__content-container">
          <h3 className="loan-value-modal__title">Qual valor quer simular?</h3>
          <p className="loan-value-modal__description">
            Valor máximo R$ 500.000,00
          </p>

          <div className="loan-value-modal__input" data-label="valor">
            <CurrencyInput
              type="text"
              inputMode="numeric"
              pattern="[R$0-9.,]*"
              placeholder="R$ 42.000,00"
              prefix="R$"
              decimalsLimit={2}
              decimalScale={2}
              decimalSeparator=","
              allowNegativeValue={false}
              groupSeparator="."
              onValueChange={(value, name, { float }) => {
                setLoanValue(float)
              }}
              allowDecimals={true}
              fixedDecimalLength={2}
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              maxLength={8}
              className="loan-value-modal__input-field"
            />
          </div>

          <Button
            className="loan-value-modal__button"
            onClick={() => {
              makeTrackings.elementClicked({
                actionOutcome: 'input-loan-ammount',
                elementType: 'input',
                htmlId: 'fc19a58c-f240-4e17-88a6-8153d257403f',
                name: `click-form-loans-ammount-others-${loanValue}`,
                position: otherButtonPosition,
                text: toCurrency(loanValue),
              })

              handleSelectedLoan(loanValue)
            }}
          >
            Próximo
          </Button>
        </div>
      </div>
    </div>
  )

  if (isClient() && isOpen) {
    return ReactDOM.createPortal(
      InvestmentModalLayout,
      document.getElementById('modal-root')
    )
  }

  return null
}

export default LoanValueModal
